import request from '@/utils/request'


// 查询设备系列列表
export function listSeries(query) {
  return request({
    url: '/gather/gather-series/list',
    method: 'get',
    params: query
  })
}

// 查询设备系列分页
export function pageSeries(query) {
  return request({
    url: '/gather/gather-series/page',
    method: 'get',
    params: query
  })
}

// 查询设备系列详细
export function getSeries(data) {
  return request({
    url: '/gather/gather-series/detail',
    method: 'get',
    params: data
  })
}

// 新增设备系列
export function addSeries(data) {
  return request({
    url: '/gather/gather-series/add',
    method: 'post',
    data: data
  })
}

// 修改设备系列
export function updateSeries(data) {
  return request({
    url: '/gather/gather-series/edit',
    method: 'post',
    data: data
  })
}

// 删除设备系列
export function delSeries(data) {
  return request({
    url: '/gather/gather-series/delete',
    method: 'post',
    data: data
  })
}
